<template>
  <div class="flex-grow-1">
    <VBtn v-show="!paymentInProgress" id="apple-pay-button" />
    <LoadingOverlay v-show="paymentInProgress" />
  </div>
</template>

<script>
import { useOpcStore } from '@/stores/opc'
import { mapActions, mapState } from 'pinia'
const LoadingOverlay = () => import('@/components/basic/atoms/LoadingOverlay')

const _getModules = (() => {
  let cache = null

  return async () => {
    if (!cache) {
      const { useApplePay } = await import('@/services/helpers/ApplePayHelper')
      cache = { useApplePay }
    }
    return cache
  }
})()

export default {
  name: 'BraintreeApplePayButton',
  components: { LoadingOverlay },
  computed: {
    ...mapState(useOpcStore, ['paymentInProgress', 'grandTotal'])
  },
  methods: {
    ...mapActions(useOpcStore, [
      'setPaymentInProgress',
      'updatePaymentData',
      'updateCustomerInfo',
      'placeOrder'
    ]),

    isApplePaySupported () {
      // eslint-disable-next-line no-undef
      return window.ApplePaySession && ApplePaySession.supportsVersion(3) && ApplePaySession.canMakePayments()
    },

    async handleApplePayClick () {
      this.setPaymentInProgress(true)
    },

    async handleApplePayCancel () {
      this.setPaymentInProgress(false)
    },

    async handleApplePayApprove (nonce, shippingAddress, billingAddress) {
      const mapAddressFields = (address) => ({
        first_name: address.givenName,
        last_name: address.familyName,
        city: address.locality,
        country: address.countryCode.toLowerCase(),
        company: '',
        zip: address.postalCode,
        street_name: address.addressLines[0].split(' ')[0],
        street_number: address.addressLines[0].split(' ')[1]
      })

      const data = {
        tokenized_nonce: nonce,
        method_code: 'apple_pay',
        is_default_payment_method: false
      }

      await this.updatePaymentData({ data })
      await this.updateCustomerInfo({ type: 'shippingAddress', data: mapAddressFields(shippingAddress) })

      const addressesAreSame = JSON.stringify(shippingAddress) === JSON.stringify(billingAddress)
      if (!addressesAreSame) {
        await this.updateCustomerInfo({ type: 'billingAddress', data: mapAddressFields(billingAddress) })
        await this.updateCustomerInfo({ type: 'differentBillingAddress', data: { value: true } })
      }

      await this.placeOrder(false)
    },

    handleApplePayError () {
      this.updatePaymentData({ error: true })
      this.setPaymentInProgress(false)
    },

    async initializeApplePay () {
      const handlers = {
        onApprove: this.handleApplePayApprove,
        onError: this.handleApplePayError,
        onClick: this.handleApplePayClick,
        onCancel: this.handleApplePayCancel,
        dataCollector: this.handleDataCollector,
        getGrandTotal: this.getGrandTotal
      }

      try {
        const { useApplePay } = await _getModules()
        await useApplePay(handlers)
      } catch (error) {
        console.error('Failed to load Apple Pay module', error)
        this.hideApplePayButton()
      }
    },

    hideApplePayButton () {
      const applePayButton = document.getElementById('apple-pay-button')
      if (applePayButton) applePayButton.style.display = 'none'
    },

    handleDataCollector (data) {
      this.deviceData = JSON.parse(data)
    },

    getGrandTotal () {
      return this.grandTotal
    }
  },

  async mounted () {
    await this.$nextTick()

    if (this.isApplePaySupported()) {
      await this.initializeApplePay()
    } else {
      this.hideApplePayButton()
    }
  },
}
</script>

<style lang="scss" scoped>
#apple-pay-button {
  border-radius: 0;
  display: flex;
  width: 100%;
  height: 56px;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  -apple-pay-button-style: black;
  appearance: -apple-pay-button;
}
</style>
