<template>
  <div class="bg-white border-b position-relative">
    <div class="arrow d-block d-md-none"></div>
    <PackageClaim v-if="$root.gridBreakpoint >= $gridSizes.sm"/>
    <div class="d-flex flex-column position-relative mt-0 mt-sm-5 mt-lg-3">
      <div class="d-flex justify-content-center order-1 order-sm-3">
        <Price :price="calculated.price" class="price text-center" :show-star="false"/>
      </div>
      <div class="product-info order-2 order-sm-1 px-2 mt-5" :loading="updating">
        <h2 v-if="product.manufacturer_name" itemprop="brand" itemscope="" itemtype="https://schema.org/Brand"
            class="mt-sm-3 mb-3 font-lato font-size-s l-spacing-1 font-weight-bold text-uppercase c-gray">
          <span itemprop="name">{{ product.manufacturer_name }}</span>
        </h2>
        <h1 itemprop="name" id="name" class="font-libre font-weight-bolder font-size-l text-left mt-0 mt-md-4">
          {{ product.name }}
        </h1>
        <Tags :tags="calculated.tags" class="my-3"/>
      </div>
      <VBtn color="primary" class="flex-grow-1 order-3 order-md-4 mt-md-2" @click="scrollTo" depressed
                  data-elbaction="click:add(product)" data-elbcontext="shopping:intent">
        {{ translate('opc_claim_now') }}
      </VBtn>
      <!--TODO remove customer admin guard and replace with services available check as soon as it should be used in prod for all customers -->
      <div v-if="customerIsAdmin && expressMethodsAreAvailable" class="order-4 order-md-5 d-flex flex-column w-100">
          <span class="text-uppercase justify-content-center d-flex py-2 c-gray font-size-s l-spacing-1 font-weight-bold">
            - {{ translate('opc_express_payment') }} -
          </span>
          <div class="d-flex w-100 justify-content-center">
              <div 
                  v-if="loadGooglePay" 
                  class="d-flex w-100" 
                  :class="{'pr-2': loadApplePay}"
                >
                <!-- TODO INSERT GOOGLE PAY BUTTON HERE -->
              </div>
              <div 
                v-if="loadApplePay" 
                :class="{'pl-2': loadGooglePay}"
                class="d-flex w-100"
              > 
                <BraintreeApplePayButton /> 
              </div>
          </div>
        </div>
      <div class="mt-4 mt-sm-0 mb-0 mb-sm-3 text-left order-4 order-sm-2 position-relative">
        <div class="description font-libre font-size-regular l-height-25 px-2 pb-3 pb-md-0" :loading="updating"
             v-html="product.description"></div>
        <div v-show="!updating" class="gradient-bottom"></div>
      </div>
    </div>
  </div>
</template>
<script>
import PackageClaim from '@/components/opc/atoms/PackageClaim'

import { useOpcStore } from '@/stores/opc'
import { useCustomerStore } from '@/stores/customer'
import { mapState } from 'pinia'
import BraintreeApplePayButton from '@/components/opc/atoms/BraintreeApplePayButton'

const Tags = () => import('@/components/basic/molecules/Tags')
const Price = () => import('@/components/basic/molecules/Price')

export default {
  name: 'TheOpcStageInfo',
  components: { BraintreeApplePayButton, PackageClaim, Tags, Price },
  computed: {
    ...mapState(useOpcStore, ['refreshPaymentMethods', 'calculated', 'product', 'updating', 'payment']),
    ...mapState(useCustomerStore, ['customerIsAdmin']),
    loadApplePay() {
      return window.ApplePaySession && this.payment.availableMethods.includes('apple_pay')
    },
    loadGooglePay() {
      //TODO needs to be adjusted as soon as google pay is integrated
      // return this.payment.availableMethods.includes('google_pay')
      return false
    },
    expressMethodsAreAvailable() {
      return this.loadApplePay || this.loadGooglePay
    }
  },
  methods: {
    async scrollTo () {
      const { default: DomHelper } = await import('@/services/helpers/DomHelper')
      DomHelper.scrollTo('#opc-body')
    }
  },
  async created () {
    await this.refreshPaymentMethods()
  },
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
  .description {
    height: 210px;
    overflow-y: scroll;
    padding-bottom: 1rem;
  }
  .gradient-bottom {
    width: 100%;
    height: 2rem;
    background: linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
    position: absolute;
    bottom: -5px;
  }
}
.product-info {
  min-height: 110px;
}
.price {
  height: 95px;
}
::v-deep {
  .price-info {
    font-size: $font-size-xxxl;
  }

  .tag {
    padding-block: 5px;
  }

  .final-price {
    font-size: $font-size-xxxl;
  }

  .price-per-unit {
    font-size: $font-size-s;
  }
}
</style>
